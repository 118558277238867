import PropTypes from "prop-types";
import Icon from "./index";

const Doc = (props) => Icon({
    ...props,
    iconName: "document",
  });

Doc.defaultProps = {
  isAriaHidden: true,
  alt: "document",
};

Doc.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default Doc;
