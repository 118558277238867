import PropTypes from "prop-types";
import Icon from "./index";

const SupportIcon = (props) => Icon({
    ...props,
    dir: "support",
    iconName: props.icon,
  });

SupportIcon.defaultProps = {
  isAriaHidden: false,
  alt: "support",
};

SupportIcon.propTypes = {
  alt: PropTypes.string.isRequired,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default SupportIcon;
