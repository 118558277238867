import PropTypes from "prop-types";
import Icon from "./index";

import { createClassName } from "../../helpers";
import "./style.scss";

const cn = createClassName("arrow-to-right");

const ArrowToRight = (props) => Icon({
    ...props,
    className: `${props.withSpace && cn()} ${props.className}`,
    iconName: `arrow-to-right-${props.color}`,
  });

ArrowToRight.defaultProps = {
  isAriaHidden: true,
  alt: "arrow to right",
  color: "primary-main",
  className: "",
  withSpace: false,
};

ArrowToRight.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
  withSpace: PropTypes.bool,
};

export default ArrowToRight;
