import { createRef, useEffect } from "react";
import linkProps, { fontModificationsMap } from "../propTypes";

import { createClassName } from "../../../helpers";

const cn = createClassName("link");

const AnchorLink = (props) => {
  const link = createRef();

  const scrollToTarget = (el) => {
    const currentPosition = el.getBoundingClientRect().top;
    return window.scrollBy({ top: currentPosition - 90, behavior: "smooth" });
  };

  useEffect(() => {
    const target = document.getElementById(props.href);

    link.current.addEventListener("click", (e) => {
      e.preventDefault();
      scrollToTarget(target);
    });
  }, [link.current]);

  return (
    <a
      ref={link}
      href={`#${props.href}`}
      className={`${cn(fontModificationsMap(props))} ${props.className}`}
    >
      {props.children}
    </a>
  );
};

AnchorLink.defaultProps = { ...linkProps[0] };
AnchorLink.propTypes = { ...linkProps[1] };

export default AnchorLink;
