import PropTypes from "prop-types";

import { createClassName } from "../../../helpers";
import "../style.scss";

export const cn = createClassName("container");

const AccentContainer = ({ children, className }) => (
  <div className={`${cn("background")} ${className}`}>
    <div className={cn("background", { gradient: true })}>
      <div className={cn("content")}>{children}</div>
    </div>
  </div>
  );

AccentContainer.defaultProps = {
  className: "",
};

AccentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default AccentContainer;
