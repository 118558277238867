import "./style.scss";

import { Trans, useTranslation } from "react-i18next";

import PropTypes from "prop-types";
import ContactUs from "../Links/LinkOnPage/_contactUs";
import Doc from "../Icons/Doc";
import Heading from "../Heading";
import LinkOnPage from "../Links/LinkOnPage";
import { createClassName } from "../../helpers";

const cn = createClassName("hmq");

const HMQ = (props) => {
  const { t } = useTranslation("strings");
  return (
    <div className={`${cn({ section: true })} ${props.className}`}>
      <div className={cn({ section: "icon" })}>
        <Doc />
      </div>
      <div className={cn({ section: "content" })}>
        <Heading level={4} weight="medium">
          {props.title ? props.title : t("hmq.title")}
        </Heading>
        {props.description ? (
          props.description
        ) : (
          <Trans i18nKey="strings:hmq.description">
            Browse the
            <LinkOnPage href="/support/#docs-desktop" color="primary-main">
              documentation and support
            </LinkOnPage>
            section or
            <ContactUs linkOnForm>contact us</ContactUs>
            .
          </Trans>
          )}
      </div>
    </div>
  );
};

HMQ.defaultProps = {
  title: "",
  description: "",
  className: "",
};

HMQ.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

export default HMQ;
