import PropTypes from "prop-types";

import Link from "..";

const ContactUs = ({ children, ...props }) => (
  <Link
    className={props.className}
    href={`${props.linkOnForm ? "/support/#contact-support" : "/contacts/"}`}
    color="primary-main"
    isRel={false}
  >
    {children}
  </Link>
);

ContactUs.defaultProps = {
  text: "contact us",
  className: "",
  isRu: false,
  linkOnForm: false,
};
ContactUs.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  isRu: PropTypes.bool,
  linkOnForm: PropTypes.bool,
};

export default ContactUs;
